<template>
  <section class="bando-head">
    <h1 v-if="route.params.id">Modifier un bénéficiaire</h1>
    <h1 v-else>Ajouter un bénéficiaire</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">Informations du bénéficiaire</h2>

      <!-- INFOS -->
      <form @submit.prevent="create">
        <div class="bloc-infos bloc-shadow" v-if="loading">
          <content-loader
            :speed="2"
            style="opacity: 0.5"
            primaryColor="#ffc928"
            secondaryColor="#ecebeb"
          >
            <circle cx="80" cy="20" r="8" />
            <rect x="105" y="15" rx="5" ry="5" width="220" height="10" />
            <circle cx="80" cy="50" r="8" />
            <rect x="105" y="45" rx="5" ry="5" width="220" height="10" />
            <circle cx="80" cy="80" r="8" />
            <rect x="105" y="75" rx="5" ry="5" width="220" height="10" />
            <circle cx="80" cy="110" r="8" />
            <rect x="105" y="105" rx="5" ry="5" width="220" height="10" />
            <circle cx="80" cy="140" r="8" />
            <rect x="105" y="135" rx="5" ry="5" width="220" height="10" />
          </content-loader>
        </div>
        <template v-if="!loading">
          <div class="bloc-infos bloc-shadow">
            <div class="form-group row">
              <label for="adresse" class="col col-12 col-md-3">Adresse*</label>
              <div class="col col-12 col-md-6">
                <Address
                  ref="ad"
                  @input="suggestAddress($event)"
                  @result="setAdresse($event)"
                  :items="adresses"
                  :def="beneficiaire.adresse"
                  :isAsync="true"
                  @update:def="log($event)"
                />
              </div>
              <div class="col col-md-3 mt-3 mt-lg-0 text-right">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="checkAddress"
                >
                  Tester l'adresse
                </button>
              </div>
            </div>
            <div class="form-group row">
              <label for="civilite" class="col col-12 col-md-3">Civilité</label>
              <div class="col col-12 col-md-3">
                <select
                  class="custom-select"
                  name="civilite"
                  v-model="beneficiaire.civilite"
                >
                  <option value="mme">Mme</option>
                  <option value="m">M</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="nom" class="col col-12 col-md-3">Nom*</label>
              <div class="col col-6 col-md-3">
                <input
                  type="text"
                  class="form-control"
                  name="prenom"
                  placeholder="Votre prénom"
                  v-model="beneficiaire.prenom"
                />
              </div>
              <div class="col col-6 col-md-3">
                <input
                  type="text"
                  class="form-control"
                  name="nom"
                  placeholder="Votre nom"
                  v-model="beneficiaire.nom"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                for="reference"
                class="col col-12 col-md-3"
                v-if="route.params.id"
              >
                Référence bénéficiaire
              </label>
              <label for="reference" class="col col-12 col-md-3" v-else>
                Référence bénéficiaire*
              </label>
              <div class="col col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  name="reference"
                  placeholder="Ex : 045658912-2"
                  v-if="route.params.id"
                  v-model="beneficiaire.reference"
                  readonly
                />
                <input
                  type="text"
                  class="form-control"
                  name="reference"
                  placeholder="Ex : 045658912-2"
                  v-else
                  v-model="beneficiaire.reference"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="tel-mobile" class="col col-12 col-md-3">
                Téléphone mobile<br /><i
                  >(Obligatoire pour<br />les envois de SMS)</i
                >
              </label>
              <div class="col col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  name="tel-mobile"
                  placeholder="Mobile"
                  v-model="beneficiaire.telephone_mobile"
                  size="14"
                  maxlength="14"
                  @keyup="autoTelMobile"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="tel-fixe" class="col col-12 col-md-3">
                Téléphone fixe
              </label>
              <div class="col col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  name="tel-fixe"
                  size="14"
                  maxlength="14"
                  placeholder="Fixe"
                  v-model="beneficiaire.telephone_fixe"
                  @keyup="autoTelFixe"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="adresse-plus" class="col col-12 col-md-3">
                Complément adresse
              </label>
              <div class="col col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  name="adresse-plus"
                  placeholder="Ex : Appt B2, porte 4, code 1234..."
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="email" class="col col-12 col-md-3">Email</label>
              <div class="col col-12 col-md-6">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  placeholder="xxx@yyy.com"
                  v-model="beneficiaire.email"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="date-naissance" class="col col-12 col-md-3">
                Date de naissance
              </label>
              <div class="col col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  name="date-naissance"
                  placeholder="Ex : 09/03/1977"
                  v-model="birthday"
                  size="10"
                  maxlength="10"
                  @keyup="autoDate"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="commentaire" class="col col-12 col-md-3">
                Commentaire
              </label>
              <div class="col col-12 col-md-6">
                <textarea
                  name="commentaire"
                  rows="2"
                  class="form-control"
                  placeholder="Ex : Appelez-moi avant d'arriver. Merci."
                  v-model="beneficiaire.commentaire"
                >
                </textarea>
              </div>
            </div>
          </div>

          <p class="pl-5"><small>* Informations obligatoires</small></p>

          <!-- Buttons -->
          <div
            class="text-center mb-5 pb-5"
            v-if="isOperateur || isResponsable"
          >
            <button
              type="reset"
              class="btn btn-secondary mx-3"
              @click="goTo('/liste-beneficiaires')"
            >
              Abandonner
            </button>
            <button type="submit" class="btn btn-primary mx-3">Valider</button>
          </div>
        </template>
      </form>

      <h2 class="title">Rendez-vous avec le Facteur Service Expert</h2>

      <div class="bloc-fse bloc-shadow" v-if="loading">
        <content-loader
          :speed="2"
          style="opacity: 0.5"
          primaryColor="#ffc928"
          secondaryColor="#ecebeb"
        >
          <circle cx="80" cy="20" r="8" />
          <rect x="105" y="15" rx="5" ry="5" width="220" height="10" />
          <circle cx="80" cy="50" r="8" />
          <rect x="105" y="45" rx="5" ry="5" width="220" height="10" />
          <circle cx="80" cy="80" r="8" />
          <rect x="105" y="75" rx="5" ry="5" width="220" height="10" />
          <circle cx="80" cy="110" r="8" />
          <rect x="105" y="105" rx="5" ry="5" width="220" height="10" />
          <circle cx="80" cy="140" r="8" />
          <rect x="105" y="135" rx="5" ry="5" width="220" height="10" />
        </content-loader>
      </div>

      <template v-if="!loading">
        <template v-for="lastRdv in beneficiaire.rdvs" :key="lastRdv.id">
          <!-- FSE -->
          <div class="bloc-fse bloc-shadow">
            <div class="infos">
              <img src="@/assets/img/icon-fse.png" alt="FSE" />
              <div>
                <h3>{{ lastRdv.fse.prenom }} {{ lastRdv.fse.nom }}</h3>
                Etablissement : <strong>LABEGE PDC1</strong><br />
                Zone : <strong>{{ zoneVacation }}</strong>
              </div>
            </div>
            <div class="infos">
              <img src="@/assets/img/icon-calendar.png" alt="Date du rdv" />
              <div>
                <h3>
                  {{ dateStr(lastRdv) }} <br />
                  de {{ startTimeStr(lastRdv) }} à {{ endTimeStr(lastRdv) }}
                </h3>
              </div>
            </div>
          </div>

          <!-- Buttons -->
          <div class="text-center but" v-if="isOperateur || isResponsable">
            <button
              type="reset"
              class="btn btn-secondary mx-3"
              @click.prevent="cancelRdv(lastRdv.uuid)"
            >
              Annuler le RDV
            </button>
            <button
              type="submit"
              class="btn btn-primary mx-3"
              @click.prevent="goTo('/rdv/' + lastRdv.uuid)"
            >
              Modifier le RDV
            </button>
          </div>
        </template>
      </template>
    </section>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { mapGetters } from "vuex";
import axios from "axios";
import { ContentLoader } from "vue-content-loader";
import Address from "@/components/Address.vue";
import { DateTime, Settings } from "luxon";

Settings.defaultLocale = "fr";

export default {
  name: "BeneficiaireEdit",
  components: {
    Address,
    ContentLoader
  },
  data() {
    return {
      beneficiaire: {
        adresse: "",
        adresses: [
          {
            address_line4_number: "",
            address_line4_name: "",
            address_line6_zipcode: "",
            address_line6_city: ""
          }
        ],
        civilite: "",
        nom: "",
        prenom: "",
        telephone_mobile: "",
        telephone_fixe: "",
        email: "",
        date_naissance: "",
        commentaire: "",
        reference: "",
        rdvs: []
      },
      adresses: [],
      adressesFull: [],
      zonesVacations: [],
      route: {},
      error: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ]),
    // adresse() {
    //   return (
    //     this.beneficiaire.adresses[0].address_line4_number +
    //     " " +
    //     this.beneficiaire.adresses[0].address_line4_name +
    //     " " +
    //     this.beneficiaire.adresses[0].address_line6_zipcode +
    //     " " +
    //     this.beneficiaire.adresses[0].address_line6_city
    //   ).trim();
    // },
    civilite() {
      return (
        this.beneficiaire.civilite.charAt(0).toUpperCase() +
        this.beneficiaire.civilite.slice(1)
      );
    },
    zoneVacation() {
      const zv = this.zonesVacations.filter(z => {
        let b = false;
        z.communes.forEach(c => {
          if (c.cp == this.beneficiaire.adresses[0].address_line6_zipcode) {
            b = true;
          }
        });
        return b;
      });
      return zv && zv[0] ? zv[0].nom : "";
    },
    lastRdv() {
      if (this.beneficiaire.rdvs) {
        let rdvs = this.beneficiaire.rdvs.filter(r => r.etat === "O");
        if (rdvs && rdvs[0]) {
          rdvs = rdvs.sort((a, b) => b - a);
          return rdvs[0];
        }
      }
      return null;
    },
    birthday: {
      get() {
        if (!this.beneficiaire.date_naissance) {
          return "";
        }
        if (
          this.beneficiaire.date_naissance.match(/^(\d\d\d\d-\d\d-\d\d)/g) &&
          DateTime.fromISO(this.beneficiaire.date_naissance).isValid
        ) {
          return DateTime.fromISO(this.beneficiaire.date_naissance)
            .setLocale("fr")
            .toLocaleString(DateTime.DATE_SHORT);
        }
        return this.beneficiaire.date_naissance;
      },
      set(newValue) {
        if (
          newValue.match(/^(\d\d\/\d\d\/\d\d\d\d)$/g) &&
          DateTime.fromFormat(newValue, "dd/MM/yyyy").isValid
        ) {
          this.beneficiaire.date_naissance = DateTime.fromFormat(
            newValue,
            "dd/MM/yyyy"
          ).toISO();
        } else {
          this.beneficiaire.date_naissance = newValue;
        }
      }
    }
  },
  created() {
    const route = useRoute();
    this.route = route;
    if (route.params.id) {
      this.loading = true;
      axios
        .get(process.env.VUE_APP_API + "/beneficiaire/" + route.params.id)
        .then(resp => {
          this.beneficiaire = resp.data;
          this.beneficiaire.rdvs = this.beneficiaire.rdvs
            .filter(
              r =>
                r.etat == "O" && DateTime.fromISO(r.date_start) > DateTime.now()
            )
            .sort((a, b) => a.id - b.id);
          this.loading = false;
        })
        .catch(() => {
          this.$swal.fire("Une erreur s'est produite.");
          this.loading = false;
        });
    }
    axios
      .get(process.env.VUE_APP_API + "/zone-vacation/list")
      .then(resp => {
        this.zonesVacations = resp.data;
      })
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  methods: {
    dateStr(rdv) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      let dateStr = new Date(rdv.date_start).toLocaleDateString(
        "fr-FR",
        options
      );
      return dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
    },
    startTimeStr(rdv) {
      const options = { hour: "2-digit", minute: "2-digit" };
      return new Date(rdv.date_start).toLocaleTimeString("fr-FR", options);
    },
    endTimeStr(rdv) {
      const options = { hour: "2-digit", minute: "2-digit" };
      return new Date(new Date(rdv.date_end).getTime()).toLocaleTimeString(
        "fr-FR",
        options
      );
    },
    setAdresse(i) {
      const adresse = this.adressesFull[i].properties;
      this.beneficiaire.adresses[0].address_line4_number = adresse.housenumber;
      this.beneficiaire.adresses[0].address_line4_name = adresse.street;
      this.beneficiaire.adresses[0].address_line6_zipcode = adresse.postcode;
      this.beneficiaire.adresses[0].address_line6_city = adresse.city;
      this.beneficiaire.adresse = adresse.label;
    },
    log(data) {
      this.beneficiaire.adresse = data;
    },
    goTo(url) {
      this.$router.push(url);
    },
    cancelRdv(uuid) {
      axios
        .post(process.env.VUE_APP_API + "/rdv/cancel/" + uuid)
        .then(() => {
          this.showAlert("Le rendez-vous a bien été annulé.");
        })
        .catch(() => {
          this.showAlert("Une erreur s'est produite.");
        });
    },
    create: function() {
      // this.loading = true;
      this.error = false;
      delete this.beneficiaire.rdvs;
      let msg = "";

      if (!this.beneficiaire.nom.length) {
        this.error = true;
        msg += "Le nom est obligatoire" + "\n";
      }

      if (!this.beneficiaire.prenom.length) {
        this.error = true;
        msg += "Le prénom est obligatoire" + "\n";
      }

      if (!this.beneficiaire.reference.length) {
        this.error = true;
        msg += "La référence est obligatoire" + "\n";
      }

      if (
        this.birthday &&
        !DateTime.fromFormat(this.birthday, "dd/MM/yyyy").isValid
      ) {
        this.error = true;
        msg += "La date de naissance est invalide" + "\n";
      }

      if (
        this.birthday &&
        DateTime.fromFormat(this.birthday, "dd/MM/yyyy") > DateTime.now()
      ) {
        this.error = true;
        msg += "La date de naissance est dans le futur" + "\n";
      }

      if (this.error) {
        this.$swal.fire(msg);
      } else {
        axios
          .post(
            process.env.VUE_APP_API + "/beneficiaire/save",
            this.beneficiaire
          )
          .then(() => {
            this.showAlert("Le bénéficiaire a bien été enregistré.");
          })
          .catch(() => {
            this.$swal.fire("Une erreur s'est produite.");
          });
      }
    },
    showAlert(txt) {
      this.$swal
        .fire(txt)
        .then(() => this.$router.push("/liste-beneficiaires"));
    },
    autoDate(data) {
      this.birthday = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d/]/g, "");
    },
    autoTelMobile(data) {
      this.beneficiaire.telephone_mobile = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/[^\d\s]/g, "");
    },
    autoTelFixe(data) {
      this.beneficiaire.telephone_fixe = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/[^\d\s]/g, "");
    },
    suggestAddress: function(adresse) {
      // this.loading = true;
      this.error = false;

      if (typeof adresse === "string") {
        axios
          .post(process.env.VUE_APP_API + "/adresse/suggest", { adresse })
          .then(resp => {
            this.adresses = resp.data.map(a => a.properties.label);
            this.adressesFull = resp.data;
          })
          .catch(() => {
            this.$swal.fire("Une erreur s'est produite.");
          });
      }
    },
    checkAddress: function() {
      // this.loading = true;
      this.error = false;

      const adresse = this.$refs.ad.search;

      axios
        .post(process.env.VUE_APP_API + "/adresse/test", { adresse })
        .then(resp => {
          const cp = resp.data.postal_code;
          const ville = resp.data.city;
          const num = resp.data.number;
          const street = resp.data.street;
          let b = false;
          this.zonesVacations.filter(z => {
            z.communes.forEach(c => {
              if (c.cp == cp) {
                b = true;
              }
            });
            return b;
          });
          if (b) {
            this.$swal.fire("Adresse éligible");
            this.beneficiaire.adresses[0].address_line4_number = num;
            this.beneficiaire.adresses[0].address_line4_name = street;
            this.beneficiaire.adresses[0].address_line6_zipcode = cp;
            this.beneficiaire.adresses[0].address_line6_city = ville;
          } else {
            this.$swal.fire(
              "La ville " +
                cp +
                " " +
                ville +
                " n'est rattachée à aucune zone de vacation de l'établissement."
            );
          }
        })
        .catch(() => {
          this.$swal.fire("Une erreur s'est produite.");
        });
    }
  }
};
</script>

<style scope lang="scss">
.content {
  .bloc-shadow {
    margin-bottom: 3rem;
  }

  .bloc-infos {
    padding: 2rem 3rem;
    position: relative;

    @media screen and (min-width: 992px) {
      padding: 2rem 4rem 2rem 1rem;
    }

    .col {
      padding: 0 1rem;
    }

    .form-group {
      align-items: center;

      label {
        padding-bottom: 0;
        padding-top: 0;

        @media screen and (min-width: 992px) {
          text-align: right;
        }
      }
    }

    .btn {
      height: 4.5rem;
      padding: 1rem 2rem;
    }
  }

  .text-center {
    margin-bottom: 3rem;
  }

  .bloc-fse {
    padding: 2rem;

    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: space-between;
    }

    h3 {
      margin-bottom: 0;
    }

    .infos {
      display: flex;
      align-items: flex-start;

      &:first-child {
        min-width: 55%;
        flex-basis: 55%;
        margin-bottom: 2rem;

        @media screen and (min-width: 992px) {
          margin: 0;
          padding-right: 3rem;
        }
      }

      &:last-child {
        min-width: 45%;
        flex-basis: 45%;
      }

      img {
        margin-right: 2.5rem;
      }

      div {
        padding-top: 1rem;
      }
    }
  }
}
</style>
